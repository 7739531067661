import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    margin-top: 10px;
`;

const Info = styled.input`
    margin-bottom: 25px;
    width: 100%;
    border: solid #fff;
    border-width: thin;
    padding: 16px;
    box-shadow: inset 0px 0px 5px 0px #00000038;
    :focus {
        outline: none;
    }
    ::placeholder {
        color: #000;
    }
`;

const Message = styled.textarea`
    margin-bottom: 25px;
    height: 150px;
    border: solid #fff;
    border-width: thin;
    box-shadow: inset 0px 0px 5px 0px #00000038;
    :focus {
        outline: none;
    }
    ::placeholder {
        color: #000;
    }
`;

const Label = styled.div`
    font-size: 16px;
    font-weight: 600;
`;

const Submit = styled.button`
    background-color: #000;
    color: #fff;
    padding: 10px;
`;

export {
    Container,
    Info,
    Label,
    Submit,
    Message,
}