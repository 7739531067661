import React from "react"
import {Container, Menu, MenuItem, Title, Stripes, Number, Label, Break} from "../styles/sideMenuStyle"
import StripeImg from "../images/Stripes@2x.png"
import InlineAddress from "./InlineAddress";

const ContactMenu = () => (
    <Container>
        <Number>{process.env.GATSBY_PHONE_NUMBER}</Number>
        <Stripes src={StripeImg}/>
        <Title>PHONE SUPPORT</Title>
        <Break/>
        <Menu>
            <Label>OFFICE:</Label>
            <MenuItem>{process.env.GATSBY_PHONE_NUMBER}</MenuItem>
            {/*<Label>BILLING:</Label>
            <MenuItem>{process.env.GATSBY_BILLING_NUMBER}</MenuItem>
            <Label>ATTACHMENTS:</Label>
            <MenuItem>{process.env.GATSBY_ATTACHMENTS_NUMBER}</MenuItem>
            <Label>SALES &amp; RENTALS:</Label>
            <MenuItem>{process.env.GATSBY_SALES_NUMBER}</MenuItem>
            <Label>SERVICE:</Label>
            <MenuItem>{process.env.GATSBY_SERVICE_NUMBER}</MenuItem>
            <Label>MARKETING:</Label>
            <MenuItem>{process.env.GATSBY_MARKETING_NUMBER}</MenuItem>*/}
            <Label>FAX:</Label>
            <MenuItem>{process.env.GATSBY_FAX_NUMBER}</MenuItem>
        </Menu>
        <Title>EMAIL SUPPORT</Title>
        <Break/>
        <Menu>
            <Label>GENERAL SUPPORT:</Label>
            <MenuItem>{process.env.GATSBY_EMAIL}</MenuItem>
            <Label>BILLING:</Label>
            <MenuItem>{process.env.GATSBY_BILLING_EMAIL}</MenuItem>
            <Label>ATTACHMENTS:</Label>
            <MenuItem>{process.env.GATSBY_ATTACHMENTS_EMAIL}</MenuItem>
            <Label>SALES &amp; RENTALS:</Label>
            <MenuItem>{process.env.GATSBY_SALES_EMAIL}</MenuItem>
            <Label>SERVICE:</Label>
            <MenuItem>{process.env.GATSBY_SERVICE_EMAIL}</MenuItem>
            <Label>MARKETING:</Label>
            <MenuItem>{process.env.GATSBY_MARKETING_EMAIL}</MenuItem>
        </Menu>
        <Title>ADDRESS</Title>
        <Break/>
        <Menu>
            <Label>MAILING:</Label>
            <MenuItem>
              <InlineAddress
                address1={process.env.GATSBY_MAIL_ADDRESS1}
                address2={process.env.GATSBY_MAIL_ADDRESS2}
                city={process.env.GATSBY_MAIL_CITY}
                state={process.env.GATSBY_MAIL_STATE}
                zip={process.env.GATSBY_MAIL_ZIP} />
            </MenuItem>
            <Label>YARD / OFFICE:</Label>
            <MenuItem>
              <InlineAddress
                address1={process.env.GATSBY_YARD_ADDRESS1}
                address2={process.env.GATSBY_YARD_ADDRESS2}
                city={process.env.GATSBY_YARD_CITY}
                state={process.env.GATSBY_YARD_STATE}
                zip={process.env.GATSBY_YARD_ZIP} />
            </MenuItem>
            <Label>CORPORATE OFFICE:</Label>
            <MenuItem>
              <InlineAddress
                address1={process.env.GATSBY_OFFICE_ADDRESS1}
                address2={process.env.GATSBY_OFFICE_ADDRESS2}
                city={process.env.GATSBY_OFFICE_CITY}
                state={process.env.GATSBY_OFFICE_STATE}
                zip={process.env.GATSBY_OFFICE_ZIP} />
            </MenuItem>
        </Menu>
        <Stripes src={StripeImg}/>
    </Container>
  )

  export default ContactMenu