import styled from "styled-components";

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 95px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    @media (max-width: 769px) {
      flex-direction: column;
      margin: 0;
      width: 90%;
      margin-left: 5%;
      align-items: center;
    }
`;

const Bar = styled.h1`
    text-align: center;
    background-color: #202620;
    color: #fff;
    padding: 25px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    max-width: 1250px;
    @media (max-width: 769px) {
      flex-direction: column;
      width: 100%;
      align-items: center;
      margin: 0;
    }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  text-align: left;
  margin: 10px;
  margin-left: 0px;
  @media (max-width: 769px) {
    width: 100%;
    padding: 25px;
    align-self: center;
  }
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
  margin: 10px;
  margin-left: 0px;
`;

const SubTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin: 10px;
  margin-left: 0px;
`;

const Para = styled.div`
  font-size: 16px;
  margin: 10px;
  margin-left: 0px;
`;


export {
    Container,
    Bar,
    Info,
    Title,
    SubTitle,
    Para,
    Wrapper,
}