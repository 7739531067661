import React, { useState } from "react"
import {Container} from "../styles/contactFormStyles"
import Webform from "gatsby-drupal-webform";
import WebformEntityInput from "../components/WebformEntityInput";
import WebformEntitySubmit from "../components/WebformEntitySubmit";

const Form = ({webform}) => {
    const [submitted, setSubmitted] = useState(false);
    return (
        <>
        <Container>
            {submitted ? (<p>Successfully submitted!</p>) : null}
            <Webform
              webform={webform}
              endpoint={'https://app.srequipment.net/react_webform_backend/submit'}
              customComponents={{
                  webform_name: WebformEntityInput,
                  textfield: WebformEntityInput,
                  email: WebformEntityInput,
                  tel: WebformEntityInput,
                  textarea: WebformEntityInput,
                  webform_actions: WebformEntitySubmit
              }}
              onSuccess={() => setSubmitted(true)}
            />
        </Container>
        </>
    )
}

export default Form