import React from 'react';
import styled from 'styled-components';
import {Submit} from "../styles/contactFormStyles"

const WebformEntitySubmit = ({ element }) => {
  const submitLabel = element.attributes.find(attr => attr.name === "#submit__label")

  return (
    <Wrapper>
      <Submit id={'contactform'} type={'submit'}>
        {submitLabel.value}
      </Submit>
    </Wrapper>
  )
};

const Wrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
`;

export default WebformEntitySubmit;
