import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Bar, Info, Title, SubTitle, Para, Wrapper} from "../styles/contactStyles"
import ContactMenu from "../components/contactMenu"
import Form from "../components/form"
import {graphql} from "gatsby";

const Contact = ({ data: { webformWebform: webform }}) => (
  <Layout>
    <SEO 
    title="Contact" 
    description="Have a question or concern? Call, email, or fill out our form at the bottom of this page and we will contact you within 24 hours. Thank you for choosing SR Affordable Equipment for all your construction equipment sales and service needs!"
    keywords={["Contact Us", "Buying Used Heavy Equipment", "Selling Used Heavy Equipment", "General Questions and Support", process.env.GATSBY_PHONE_NUMBER, ]}
    />
    <Bar>CONTACT SR EQUIPMENT</Bar>
    <Container>
      <Wrapper>
        <Info style={{order: "0"}}>
            <Title>Contact SR Equipment</Title>
            <SubTitle>Contact SR Equipment for Buying and Selling Used Heavy Equipment</SubTitle>
            <Para>Have questions or need assistance with buying or selling used heavy equipment? Our dedicated and knowledgeable team at SR Equipment, LLC is here to help. Reach out to us via our Contact Us page, and we will promptly respond to address your concerns and provide expert guidance. Let us be your trusted partner in the world of used construction equipment sales.</Para>
            <SubTitle>General Contact (Fill Out the form for General Questions and Support)</SubTitle>
            <Form webform={webform} />
        </Info>
        <ContactMenu style={{order: "2"}}/>
      </Wrapper > 
    </Container >
  </Layout>
)

export default Contact

export const query = graphql`
  query {
    webformWebform(drupal_internal__id: { eq: "contact" }) {
      drupal_internal__id
      elements {
			  name
			  type
			  attributes {
				  name
		  		value
			  }
		  } 
    }
  }
`